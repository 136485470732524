@import './react-tel-input.css';

html,
body {
  padding: 0;
  margin: 0;
  font-family: Recoleta, Good Sans, Poppins, Domine, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: #3ca9f5;
  text-decoration: none;
}

.sunnyside a {
  color: #124A2C;
}

.sunnyside-home body {
  font-family: Recoleta, Good Sans, Poppins, Domine, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Domine";
  src: url("/fonts/Domine/Domine-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Karla";
  src: url("/fonts/Karla/Karla-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat/Montserrat-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/fonts/Poppins/Poppins-Light.ttf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/fonts/Poppins/Poppins-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/fonts/Poppins/Poppins-Medium.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("/fonts/Poppins/Poppins-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins/Poppins-Bold.ttf");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins/Poppins-Black.ttf");
    font-style: normal;
    font-weight: 800;
    font-display: swap;
}

@font-face {
  font-family: "Good Sans Medium";
  src: url("/fonts/GoodSans/GoodSans-Medium.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Good Sans Regular";
  src: url("/fonts/GoodSans/GoodSans-Regular.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Good Sans Bold";
  src: url("/fonts/GoodSans/GoodSans-Bold.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Good Sans Light";
  src: url("/fonts/GoodSans/GoodSans-Light.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Good Sans Black";
  src: url("/fonts/GoodSans/GoodSans-Black.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Good Sans Thin";
  src: url("/fonts/GoodSans/GoodSans-Thin.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Recoleta Medium";
  src: url("/fonts/Recoleta/Recoleta-Medium.otf") format("opentype");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Recoleta Regular";
  src: url("/fonts/Recoleta/Recoleta-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.react-code-input input::selection {
  background: transparent;
}

.button-fixed-width {
  width: 285px;
}
