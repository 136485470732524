/**
* React Tel Input overrides
*/
div.react-tel-input {
  font-family: Poppins;
}

.react-tel-input input.form-control {
  margin-left: 60px;
  padding-left: 0;
  font-size: 1rem;
  width: 255px;
}

.react-tel-input input.form-control, 
.react-tel-input > div.flag-dropdown {
  border: none;
  border-radius: 0;
  font-family: Poppins;
  border-bottom: solid 1px #DCE3EA;
}

.react-tel-input input.form-control:focus,
.react-tel-input input.form-control:hover,
.react-tel-input .flag-dropdown:hover {
  border-color: rgba(18, 28, 39, 0.6);
}